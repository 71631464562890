.big-title {
   font-size: 24px;
   font-weight: bolder;
}

.custom-layout {
   box-sizing: border-box;
   position: relative;
   margin-left: auto;
   margin-right: auto;
   padding-left: 0px;
   padding-right: 0px;
   margin-bottom: 3.2rem;
   max-width: 1199px;
   margin-top: 96px;
}

.main-card-dudi {
   box-shadow: $box-shadow;
}

.program-card {
   border-radius: 2rem;
   transition: 200ms;
   height: 500px !important;
   margin-top: 1rem;
   text-align: center;

   &:hover {
      transform: translateY(-3px);
   }
}

.dudi-card-desc {
   margin-top: 0.5rem;
   height: 46px;
   overflow: hidden;
   text-overflow: ellipsis;
   width: 100%;
   display: block;
   transition: opacity 200ms;
}

.card-image-dudi {
   height: 16rem;
   width: 100%;
   object-fit: cover !important;
   border-top-left-radius: 2rem !important;
   border-top-right-radius: 2rem !important;
}

.dudi-card-img-back {
   background: #e5e5e5;
   height: 16rem;
   width: 100%;
   border-top-left-radius: 2rem;
   border-top-right-radius: 2rem;
}

.card-button {
   border: none;
   border-radius: 100px;
   background: $primary-color;
   color: $secondary-color;
   margin-top: auto;
   padding-left: 3rem;
   padding-right: 3rem;

   &:hover {
      background: $primary-color;
      opacity: 0.8;
      color: $secondary-color;
   }
}

.dudi-button {
   background: rgba(20, 33, 61, 0.7);
   padding: 8px 60px;
   border: none;
   color: white;
   border-radius: 30px;
   transition: all 0.5s;

   &:hover {
      background: none;
      color: $secondary-color;
      border: 1px solid $secondary-color;
      cursor: pointer;
   }
}

.dudi-button-white {
   background: none;
   border: 1px solid white;
   padding: 8px 60px;
   color: white;
   border-radius: 30px;
   transition: all 0.5s;

   &:hover {
      background: white;
      color: $secondary-color;
      border: 1px solid white;
      cursor: pointer;
   }
}

.dudi-avatar {
   border: 2px solid $primary-color;

   &_profile {
      border: 3px solid $primary-color;
      margin-top: -100px;
   }
}

.avatar-icon {
   color: $primary-color;
   font-size: 18px;
}

.ant-input-group-addon button {
   background: $primary-color;
   color: $secondary-color;
   border: 1px solid $primary-color;

   &:hover {
      background: $primary-color;
      color: $secondary-color;
   }

   &:active {
      background: $primary-color;
      color: $secondary-color;
   }

   &:focus {
      background: $primary-color !important;
      color: $secondary-color !important;
   }
}

.back-to-top-btn {
   height: 50px;
   width: 50px;
   border-radius: 50%;
   background: $primary-color;
   color: $secondary-color;
   border: none;

   &:hover {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: $primary-color;
      color: $secondary-color;
      border: none;
      opacity: 0.8;
   }
}

.profile-big-card {
   margin-top: 100px;
   border-radius: 8px;
}

.verified-badge {
   font-size: 18px;
   color: $accent-blue;

   &-min {
      font-size: 12px;
      color: $accent-blue;
      padding-left: 6px;
   }
}

.editing-area {
   min-height: 300px;
}

.desc-wrapper {
   padding-left: 30px;

   > h1,
   h2 {
      font-size: 15px;
   }
}

.dudi-header {
   height: 96px;
   position: fixed;
   z-index: 999;
   width: 100%;
   background: #fff;
   backdrop-filter: blur(30px);
   opacity: 0.8;

   &-wrapper {
      display: flex;
      height: 96px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 1rem;
      padding-bottom: 1rem;
      max-width: 1199px;
      align-items: center;
      justify-content: space-between;

      &-nav {
         padding: 1rem;
         height: 96px;
         display: flex;
      }
   }
}

.program-price,
.ant-card-meta-title {
   font-weight: bold;
}

.program-card-container {
   background: $grey-color;
   border: none;
   border-radius: 1rem;
   box-shadow: $box-shadow;
   // padding: 1rem;

   &-title {
      margin-bottom: 1rem;
      display: flex;
      margin-left: 2rem;

      > .detail-program-title {
         > p,
         h1 {
            padding: 0;
            margin: 0;
            margin-left: 4rem;
         }

         > h1 {
            font-size: 1.7em;
            margin-left: 4rem;
         }
      }
   }
}

.dudi-nav {
   &-logo {
      height: 90%;
   }

   &-item {
      display: flex;
      list-style: none;
      align-items: center;

      > li {
         margin-left: 2rem;
      }

      &-institution {
         visibility: visible;

         @media #{$small-screen} {
            visibility: hidden;
         }
      }
   }
}

// .detail-main-wrapper {
//    background: red;
//    height: 100%;
//    display: block;
// }

.detail-program-desc-wrapper {
   font-size: 0.8rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   // height: 50%;
   // overflow: hidden;

   > h1 {
      font-size: 18px;
   }

   > h2,
   h3,
   h4,
   h5,
   h6 {
      font-size: 14px;
   }

   &-footer {
      margin-top: 1rem;
      display: flex;
      justify-content: space-around;

      &-item {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         text-align: center;

         > .dudi-tags {
            padding: 0.2rem 1rem;
            border-radius: 10rem;
            font-size: 0.8rem;

            &.nominal {
               background: #c3c3c3;
            }

            &.status {
               background: #60d5fb;
            }

            &.pengajuan {
               background: #60d17d;
            }

            &.ditolak {
               background: #e94570;
               color: #fff;
            }

            &.pending {
               background: #ffcb5f;
            }
         }
      }
   }
}
.dudi-tags {
   padding: 0.2rem 1rem;
   border-radius: 10rem;
   font-size: 0.8rem;

   &.nominal {
      background: #c3c3c3;
   }

   &.status {
      background: #60d5fb;
   }

   &.pengajuan {
      background: #60d17d;
   }

   &.ditolak {
      background: #e94570;
      color: #fff;
   }

   &.pending {
      background: #ffcb5f;
   }
}

.dudi-avatar-detail {
   height: 60px;
   width: 60px;
}

.popover-item-institution {
   display: none;

   @media #{$small-screen} {
      display: inline;
      visibility: visible;
   }
}

.beranda-container {
   height: 100vh;
   width: 100vw;
   position: relative;
   overflow: hidden;

   &-bgimage {
      position: absolute;
      bottom: 0;
      right: 0;
      // height: 650px;
      width: 100%;

      &-char {
         width: 64%;
         position: absolute;
         z-index: 2;
         bottom: 4%;
         right: 11%;
      }

      &-papan {
         width: 25%;
         position: absolute;
         z-index: 1;
         bottom: 32%;
         right: 21%;

         @media #{$small-screen} {
            width: 25%;
            position: absolute;
            z-index: 1;
            bottom: 17%;
            right: 21%;
         }
      }
   }

   &-title {
      color: #457b9d;
      display: flex;
      min-height: 80vh;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 20%;
      font-size: 56px;
      font-weight: 700;
      letter-spacing: 6px;
      line-height: 110%;

      @media #{$tabletMQ} {
         padding-left: 16px;
         font-size: 21px;
      }

      > #title-pendidikan {
         margin: -16px 0;
      }
   }
}

.video-container {
   position: relative;
   height: 68vh;
   overflow: hidden;
}

#myVideo {
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translateX(-50%) translateY(-50%);
   transform: translateX(-50%) translateY(-50%);
   min-width: 100%;
   min-height: 80%;
   width: auto;
   height: auto;
   overflow: hidden;
}

.bottom-line1 {
   height: 10px;
   width: 10rem;
   background: #457b9d;
   border-radius: 10px;

   @media #{$small-screen} {
      width: 8rem;
   }
}

.video-carousel {
   height: 360px;
   width: 640px;
   border-radius: 1rem;
}

.bottom-line2 {
   height: 10px;
   width: 15rem;
   background: #457b9d;
   border-radius: 10px;
   margin-left: 2rem;

   @media #{$small-screen} {
      width: 10rem;
   }
}

.ant-select-selection-item {
   width: 100px;
}

.header-link-active {
   font-weight: bold;
   border-bottom: 2px solid #ffcb5f;
}

.beranda-auth-button {
   height: 100px;
   // float: right;
   margin-top: 100px;
   overflow: hidden;
   padding-left: 64px;
   z-index: 999;
   position: absolute;
   right: 0;

   > h3 {
      cursor: pointer;
      color: #fff;
      font-weight: bold;
      background: #457b9d;
      padding: 4px;
      padding-left: 32px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      width: 120px;
      transform: translateX(10px);
      transition: 200ms;

      &:hover {
         transform: translateX(0);
      }
   }
}

.dudi-register-btn-home {
   background: #fff;
   border: none;
   border-radius: 10px;
   padding: 4px 32px;
   text-transform: uppercase;
   font-weight: bold;
   letter-spacing: 2px;
   color: #5472d7;

   &:hover {
      color: black;
      opacity: 0.8;
      background: #fff;
      border: none;
      border-radius: 10px;
      padding: 4px 32px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 2px;
      color: #5472d7;
   }
}

.dudi-history-step {
   > .ant-steps-item-container {
      > .ant-steps-item-content {
         width: 100% !important;
         > .ant-steps-item-title {
            margin-left: 16px !important;
         }
      }
   }
}

.embed-container {
   position: relative;
   padding-bottom: 56.25%;
   height: 0;
   overflow: hidden;
   max-width: 100%;
   border-radius: 1rem;

   iframe,
   object,
   embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
   }
}

.carousel-image {
   object-fit: cover;
   border-radius: 20px;
   width: calc(100vw / 6);

   @media #{$small-screen} {
      width: calc(100vw / 3);
   }
}

.new_footer {
   display: flex;
   padding: 64px;
   background: #333333;
   color: #fff;
   @media #{$small-screen} {
      padding: 32px;
   }

   .footer_cover {
      max-width: 100px;
      max-height: 100px;
      object-fit: cover;
   }

   .footer_address {
      flex: 1;
      padding-left: 32px;
   }

   ul {
      list-style: none;
      margin: 0;
      padding: 0;
   }

   h3 {
      margin: 0;
      font-size: 42px;
      font-weight: bold;
      line-height: 42px;
      color: #fff;

      @media #{$small-screen} {
         font-size: 24px;
         line-height: 24px;
      }
   }

   button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      img {
         @media #{$small-screen} {
            height: 64px;
            width: 64px;
         }
      }
   }
}
