@import "./Styles/variable.scss";
@import "./Styles/main.scss";

* {
  font-family: "Poppins", sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  background: #fff;
}

.site-page-header {
  background: $secondary-color;

  > .ant-page-header-heading {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 1199px;
  }

  @media #{$small-screen} {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.ant-page-header-back-button {
  color: $primary-color;

  &:hover {
    color: $primary-color;
    opacity: 0.6;
  }
}

.ant-page-header-heading-title {
  color: $primary-color;
  font-weight: 800;
}

.ant-page-header-heading-sub-title {
  color: $grey-color;
  margin-left: 16px;
}

.dudi-layout {
  background: #fff;
  min-height: 100vh;
}

.dudi-footer {
  background: $secondary-color;
}

.div-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.__main_card_table {
  border-radius: 6px;
  background: #fff;
  box-shadow: -12px 12px 24px #f0f0f0;
}

.custom-budget-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-budget-table td,
.custom-budget-table th {
  border: 1px solid #f0f0f0;
  padding: 8px;
  text-align: center;
}

.custom-budget-table td {
  border: 1px solid #f0f0f0;
  padding: 8px;
  font-weight: 300;
  font-size: 13px;
}

.custom-budget-table th {
  border: 1px solid #cdc9c9;
  background: #fafafa;
  font-weight: 500;
}

.budget-plan-table td {
  text-align: left;
}

.budget-plan-table-title {
  text-align: center !important;
}

.component-detail-main {
  background: #e6f7ff;
  transition: 500ms;
}

.component-detail-main:hover {
  background: #bae7ff;
  transition: 500ms;
}

.component-detail-main td {
  font-weight: 500;
}
