@import "../../Styles/variable.scss";

.new_home {
   min-height: 800px;

   &_section-1 {
      min-height: 100vh;
      background-color: #25284e;
      background-image: url("../../Assets/Images/home-bg.png");
      background-repeat: no-repeat;
      padding: 16px 64px;
      border-bottom: 2px solid #939393;
      // max-width: 1536px;
      &__header {
         display: flex;
         align-items: center;
         justify-content: space-between;

         &_link {
            font-size: 18px;
            color: #fff;
         }

         &_button {
            background: #576fe0;
            border: none;
            outline: none;
            border-radius: 20px;
            padding: 7px 22px;
            cursor: pointer;
            &:hover {
               background: #6373ff;
            }
            &:focus {
               background: #6373ff;
            }

            &:active {
               background: #6373ff;
            }
         }

         &_link_root {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;
            @media #{$small-screen} {
               display: none;
            }
         }

         & img {
            height: 84px;
            width: 84px;
            object-fit: cover;
         }
      }
   }
}

.program_detail_card {
   background: #fff;
   border-radius: 24px;
   padding: 32px 64px;
   margin: 64px 0;
   @media #{$small-screen} {
      padding: 32px;
   }
   &_header {
      text-align: center;
      background: #25284e;
      padding: 16px 64px;
      border-radius: 32px;
      @media #{$small-screen} {
         padding: 12px 32px;
         border-radius: 16px;
      }

      h3 {
         margin: 0px;
         font-size: 24px;
         color: #fff;
         font-weight: bold;
         @media #{$small-screen} {
            font-size: 18px;
         }
      }
   }

   &_body {
      display: flex;
      align-items: center;
      @media #{$small-screen} {
         flex-direction: column;
      }

      &_text {
         margin-left: 64px;
         padding: 32px 0;
         @media #{$small-screen} {
            margin-left: 0;
            font-size: 14px;
         }
      }

      img {
         height: 240px;
         width: 240px;
         border-radius: 120px;
         object-fit: cover;
         margin: 32px 0;

         @media #{$small-screen} {
            border-radius: 16px;
            width: 100%;
            margin: 32px 0 0 0;
         }
      }
   }
}
